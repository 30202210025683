<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn
        icon
        class="mb-1"
        @click="goBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ $t('editplan') }}</span>
    </portal>
    <BaseLoading v-if="loading"></BaseLoading>
    <!-- <PlanForm :editdata="formData" :planid="id" :jsonData="jsonData" v-else /> -->
    <v-row class="pt-4">
      <v-col cols="12" xl="9" class="text-justify">
        <v-form
          ref="form"
          v-model="valid"
        >
        <v-card flat class="transparent">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Batch Number"
                  :disabled='true'
                  v-model="formData.batchnumber"
                  :rules="true ? [v => !!v || `Batch Number is required`] : []"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  :items="lotnumberList"
                  label="Receiving Lot Number"
                  v-model="formData.lotnumber"
                  item-text="lotnumber"
                  item-value="lotnumber"
                  item-disabled="isDisable"
                  :loading="lotLoading"
                  multiple
                  :rules="true ? [v => !!v || `Receiving Lot Number is required`] : []"
                  @change="onLotChange"
                >
                  <!-- <template #item="{ item }">
                    <v-list-item :disabled="item.isDisable">
                      <v-list-item-content>
                        {{ item.lotnumber }}
                      </v-list-item-content>
                    </v-list-item>
                </template> -->
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Bird Count"
                  :disabled='true'
                  v-model="numberofbirds"
                  :rules="true ? [v => !!v || `Bird Count is required`] : []"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Receiving Weight"
                  :disabled='true'
                  :loading="receivingLoading"
                  v-model="receivingweight"
                  :rules="true ? [v => !!v || `Receiving Weight is required`] : []"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              class="text-none"
              @click="goBack"
            >
              {{ $t('labels.exit') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none"
              :disabled="disable"
              :loading="saving"
              @click="updateRecord(true)"
            >
              {{ $t('labels.update') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseLoading from '../components/base/loading.vue';

export default {
  name: 'EditPlan',
  components: {
    BaseLoading,
  },
  data() {
    return {
      valid: false,
      formData: {},
      loading: false,
      receivingLoading: false,
      lotnumberList: [],
      numberofbirds: 0,
      receivingweight: 0,
      ids: [],
      lotLoading: false,
      saving: false,
      initialLotList: [],
      disable: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.lotLoading = true;
    this.fetchRecord();
  },
  methods: {
    ...mapActions('productionPlanningVista', ['getRecordsById', 'getRecords', 'updateRecordById']),
    goBack() {
      this.$router.push({ name: 'productionPlanningVista' });
    },
    async fetchReceivingmaterial(lotnumber) {
      const res = await this.getRecords({ elementName: 'receivingmaterial', query: `?query=lotnumber=="${lotnumber}"` });
      return res;
    },
    async fetchLotNumber() {
      const res = await this.getRecords({ elementName: 'receivingdataentry' });
      if (res) {
        this.lotnumberList = res.filter((resObj) => {
          if (this.formData.lotnumber.includes(resObj.lotnumber)) {
            resObj.isDisable = true;
            return resObj;
            // eslint-disable-next-line
          } else {
            return resObj.status === false;
          }
        });
      }
      this.lotLoading = false;
    },
    async fetchRecord() {
      this.loading = true;
      this.formData = await this.getRecordsById({ elementName: 'planning', id: this.id });
      await this.fetchLotNumber();
      this.numberofbirds = this.formData.plannedquantity;
      this.receivingweight = this.formData.receivingweight;
      this.initialLotList = this.formData.lotnumber;
      this.loading = false;
    },
    async onLotChange(lot) {
      this.disable = true;
      this.receivingLoading = true;
      this.numberofbirds = 0;
      this.receivingweight = 0;
      this.ids = [];
      await this.lotnumberList.map(async (obj) => {
        if (lot.includes(obj.lotnumber)) {
          if (!(this.initialLotList.includes(obj.lotnumber))) {
            this.disable = false;
          }
          const receivingMaterial = await this.fetchReceivingmaterial(obj.lotnumber);
          receivingMaterial.map((mat) => {
            this.receivingweight += parseFloat(mat.receivingweight);
            return mat;
          });
          // If it is, convert numberofbirds attribute to a number and add it to the numberofbirds
          this.numberofbirds += Number(obj.numberofbirds);
          // Convert receivedqtyfromsupplierinkg attribute to a float and add it to receivingweight
          // this.receivingweight += parseFloat(obj.receivedqtyfromsupplierinkg);
          this.ids.push(obj._id);
        }
        this.receivingLoading = false;
        return {
        };
      });
    },
    async updateRecord(exit) {
      this.saving = true;
      const formData = {
        ...this.formData,
      };
      formData.plannedquantity = this.numberofbirds;
      formData.receivingweight = this.receivingweight;
      const updateRes = await this.updateRecordById({
        elementName: 'planning',
        payload: formData,
        id: this.id,
      });
      if (updateRes) {
        this.ids.map(async (receivingId) => {
          await this.updateRecordById({
            elementName: 'receivingdataentry',
            payload: { status: true },
            id: receivingId,
          });
        });
      }
      this.saving = false;
      if (exit) {
        this.goBack();
      }
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
#home-container {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
