<template>
  <div>
    <v-row class="pt-4">
      <v-col cols="12" xl="9" class="text-justify">
        <v-form
          ref="form"
          v-model="valid"
        >
        <v-card flat class="transparent">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Batch Number"
                  v-model="formData.batchnumber"
                  :rules="[v => !!v || `Batch Number is required`,
                  v => /^[a-zA-Z0-9]*$/.test(v)
                  || 'Batch Number can only contain alphanumeric characters']"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  outlined
                  clearable
                  :items="lotnumberList"
                  label="Receiving Lot Number"
                  v-model="formData.lotnumber"
                  item-text="lotnumber"
                  item-value="lotnumber"
                  multiple
                  :rules="true ? [v => !!v || `Receiving Lot Number is required`] : []"
                  @change="onLotChange"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Bird Count"
                  :disabled='true'
                  v-model="numberofbirds"
                  :rules="true ? [v => !!v || `Bird Count is required`] : []"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  clearable
                  color="blue darken-2"
                  label="Receiving Weight"
                  :disabled='true'
                  :loading="receivingLoading"
                  v-model="receivingweight"
                  :rules="true ? [v => !!v || `Receiving Weight is required`] : []"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              class="text-none"
              @click="goBack"
            >
              {{ $t('labels.exit') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              class="text-none"
              :disabled="!valid"
              :loading="loading"
              @click="toggleModel(true)"
              v-if="!planid"
            >
              {{ $t('labels.saveExit') }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none"
              :disabled="!valid"
              :loading="loading"
              @click="toggleModel(false)"
              v-if="!planid"
            >
              {{ $t('labels.saveAddNew') }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none"
              :disabled="!valid"
              @click="updateRecord(true)"
              v-if="planid"
            >
              {{ $t('labels.update') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      id="planconfirm"
      v-model="confirmdialog"
      persistent
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="primary">
          <span class="white--text">
            {{ $t('confirmplan') }}
          </span>
        </v-card-title>
        <v-card-text class="black--text text-h6">
          {{ $t('confirmplanmessage') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text class="text-none" @click="confirmdialog = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" class="text-none" :loading="loading" @click="saveRecord(true)">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PlanningForm',
  props: {
    editdata: {
      type: Object,
      default: null,
    },
    planid: {
      type: String,
      default: null,
    },
    jsonData: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {},
      valid: false,
      loading: false,
      receivingLoading: false,
      lotnumberList: [],
      numberofbirds: 0,
      receivingweight: 0,
      ids: [],
      supplierName: '',
      confirmdialog: false,
      saveExit: false,
    };
  },
  computed: {
    formFields() {
      return this.jsonData.fields;
    },
    element() {
      return this.jsonData.elementName;
    },
  },
  mounted() {
    this.fetchLotNumber();
    if (this.editdata) {
      this.formData = this.editdata;
    }
  },
  created() {
    if (!this.planid) {
      this.resetForm();
    }
  },
  methods: {
    ...mapActions('productionPlanningVista', ['createRecord', 'updateRecordById', 'getRecords']),
    toggleModel(exit) {
      this.confirmdialog = true;
      if (exit) {
        this.saveExit = true;
      } else {
        this.saveExit = false;
      }
    },
    async fetchLotNumber() {
      const res = await this.getRecords({ elementName: 'receivingdataentry' });
      if (res) {
        this.lotnumberList = res.filter((resObj) => resObj.status === false);
      }
    },
    async fetchReceivingmaterial(lotnumber) {
      const res = await this.getRecords({ elementName: 'receivingmaterial', query: `?query=lotnumber=="${lotnumber}"` });
      return res;
    },
    async saveRecord() {
      this.loading = true;
      const partMatrixRes = await this.getRecords({ elementName: 'partmatrix', query: '?pagenumber=1&pagesize=1' });
      const machineRes = await this.getRecords({ elementName: 'machine', query: '?pagenumber=1&pagesize=1' });
      delete partMatrixRes[0]._id;
      delete partMatrixRes[0].elementName;
      delete partMatrixRes[0].siteId;
      delete partMatrixRes[0].elementId;
      delete partMatrixRes[0].customerId;
      delete partMatrixRes[0].userId;
      delete partMatrixRes[0].createdTimestamp;
      delete partMatrixRes[0].modifiedtimestamp;
      delete partMatrixRes[0].questions;
      delete partMatrixRes[0].suppliername;
      const formData = {
        plannedquantity: this.numberofbirds,
        receivingweight: this.receivingweight,
        batchnumber: this.formData.batchnumber,
        lotnumber: this.formData.lotnumber,
        status: 'notStarted',
        ...partMatrixRes[0],
        suppliername: this.supplierName,
        partname: this.formData.batchnumber,
        eaid: machineRes[0].eaid,
        actualstart: new Date().valueOf(),
      };
      const createRes = await this.createRecord({ payload: formData, elementName: 'planning' });
      if (createRes) {
        this.ids.map(async (id) => {
          await this.updateRecordById({
            elementName: 'receivingdataentry',
            payload: { status: true },
            id,
          });
        });
      }
      this.loading = false;
      this.confirmdialog = false;
      if (this.saveExit) {
        this.goBack();
      } else {
        await this.fetchLotNumber();
      }
      this.$refs.form.reset();
    },
    async updateRecord(exit) {
      const formData = {
        ...this.formData,
        status: 'notStarted',
      };
      await this.updateRecordById({
        elementName: this.element,
        payload: formData,
        id: this.planid,
      });
      if (exit) {
        this.goBack();
      }
      this.$refs.form.reset();
    },
    resetForm() {
      // eslint-disable-next-line
      this.jsonData.fields.map((f) => this.formData[f.key] = '');
    },
    goBack() {
      this.$router.push({ name: 'productionPlanningVista' });
    },
    async onLotChange(lot) {
      this.receivingLoading = true;
      this.numberofbirds = 0;
      this.receivingweight = 0;
      this.ids = [];
      // let receivingSum = 0;
      await this.lotnumberList.map(async (obj) => {
        if (lot.includes(obj.lotnumber)) {
          const receivingMaterial = await this.fetchReceivingmaterial(obj.lotnumber);
          if (receivingMaterial) {
            receivingMaterial.map((mat) => {
              this.receivingweight += parseFloat(mat.receivingweight);
              return mat;
            });
          }
          // If it is, convert numberofbirds attribute to a number and add it to the numberofbirds
          this.numberofbirds += Number(obj.numberofbirds);
          // Convert receivedqtyfromsupplierinkg attribute to a float and add it to receivingweight
          this.ids.push(obj._id);
          this.supplierName = obj.suppliername;
        }
        this.receivingLoading = false;
        return {
        };
      });
    },
  },
};
</script>
