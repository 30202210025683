<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn
        icon
        class="mb-1"
        @click="goBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ 'New Production Plan' }}</span>
    </portal>
    <PlanForm :jsonData="jsonData" />
  </div>
</template>

<script>
import PlanForm from '../components/PlanForm.vue';
import { newform } from '../components/planform.json';

export default {
  name: 'CreateNew',
  components: {
    PlanForm,
  },
  computed: {
    jsonData() {
      return newform;
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'productionPlanningVista' });
    },
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
#home-container {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
